.contact {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  min-height: 55vh;
}

.contact-heading {
  font-family: "MyFont", sans-serif;
  text-align: center;
  border-bottom: 2px solid rgb(5, 143, 223);
  font-size: 32px;
}
.contact-heading > span {
  color: rgb(5, 143, 223);
}
.contact-container {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid rgb(5, 143, 223);
  border-top: 1px solid rgb(5, 143, 223);
  padding: 30px 30px 30px 30px;
}
.contact-container > p {
  margin: 0;
  padding: 5px 30px 5px 30px;
  text-align: center;
  font-size: 18px;
  max-width: 900px;
  font-family: "MyFont", sans-serif;
}
.contact-details-container {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 30px;
}
.contact-details {
  color: black;
  font-family: "MyFont", sans-serif;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 600;
  background-color: white;

  padding: 20px;
  justify-content: center;
}

.contact-icon {
  margin-right: 2px;
  transform: scale(0.8);
  color: rgb(5, 143, 223);
}

@media (max-width: 850px) {
  .contact-details-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
    padding: 5px;
  }
  .contact-details {
    padding: 2px 10px 2px 10px;
    margin: 0;
  }
}
