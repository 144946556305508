.sidebar-container {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  z-index: 2;
}
.sidebar-filler {
  height: 100vh;
}
.sidebar {
  background-color: white;
  height: 100vh;
  min-width: 320px;
  display: flex;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  flex-direction: column;
  gap: 60px;
  justify-content: flex-start;
  border-left: 2px solid rgb(133, 133, 133);
}

.sidebar-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 17px;
  align-items: center;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
  background-color: rgb(5, 143, 223);
}

.sidebar-heading {
  background-color: rgb(5, 143, 223);
  font-family: "MyFont", sans-serif;
  font-weight: none;
  font-size: 25px;
  color: white;
  font-weight: bold;
  text-decoration: none;
}
.sidebar-heading:hover {
  color: rgb(240, 240, 240);
}
.close-sidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: bold;
  padding: 10px;
  max-height: 40px;
  border: 2px solid white;
  border-radius: 20px;
  color: rgb(255, 253, 253);
  background-color: rgb(5, 143, 223);
}
.close-sidebar:hover {
  cursor: pointer;
  color: rgb(240, 240, 240);
  border: 2px solid rgb(240, 240, 240);
}
.sidebar-links-container {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 70px;
  justify-content: center;

  height: 40%;
  gap: 20px;
}

.sidebar-btns {
  font-size: 25px;
  padding-top: 30px;
  padding-bottom: 5px;
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-family: "MyFont", sans-serif;
  border-bottom: 1px solid rgb(5, 143, 223);
}

.sidebar-btns:hover {
  color: rgb(5, 143, 223);
  transform: scale(0.99);
}

/* Animation */

.sidebar {
  animation: 1s slide-left;
}

@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}
