@font-face {
  font-family: "MyFont";
  src: url("../fonts/RobotoSlab-VariableFont_wght.ttf");
  font-weight: normal;
  font-style: normal;
}
.hamburger {
  display: none;
  cursor: pointer;
  border: none;
  background-color: rgb(5, 143, 223);
  color: white;
  font-size: 25px;
}
.hamburger:hover {
  transform: scale(0.99);
}
.navbar-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: rgb(5, 143, 223);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
}
.navbar-btns-container {
  display: flex;
  gap: 15px;
}
.navbar-link {
  font-family: "MyFont", sans-serif;
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
}
.navbar-link:hover {
  text-decoration: underline;
  transform: scale(0.98);
}
.navbar-heading {
  font-size: 25px;
}
.navbar-heading:hover {
  transform: scale(0.99);
}

.navbar-btns {
  cursor: pointer;
  font-size: larger;
  border: none;
  background: none;
}

@media (max-width: 600px) {
  .hamburger {
    display: flex;
  }
  .navbar-btns-container {
    display: none;
  }
}
.hovered {
  color: rgb(5, 143, 223);
  font-weight: 700;
}

/* Projects-navbar */

.pnav {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgb(5, 143, 223);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
}

.pnav-link {
  font-family: "MyFont", sans-serif;
  color: white;
  font-weight: bold;
  font-size: 22px;
  text-decoration: none;
}
.pnav-link:hover {
  text-decoration: underline;
  transform: scale(0.98);
}
