@font-face {
  font-family: "MyFont";
  src: url("../fonts/RobotoSlab-VariableFont_wght.ttf");
  font-weight: normal;
  font-style: normal;
}

.home-container {
  overflow: hidden;
  display: flex;
  min-height: 92vh;
  justify-content: center;
  align-items: center;
  gap: 80px;
}
.home-container-infos {
  padding-bottom: 100px;
}

.home-heading {
  text-align: center;
  font-family: "MyFont";
  font-weight: 500;
  font-size: 60px;
  margin-bottom: 0;
}
.home-desc {
  text-align: center;
  font-family: "MyFont";
  font-weight: 400;
  font-size: 50px;
  margin-top: 0;
}
.home-desc > span {
  color: rgb(5, 143, 223);
  text-decoration: underline;
}

.home-img {
  width: 500px;
  height: auto;
  margin-bottom: 100px;
}

.home-container-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
}

.get-to-know-btn {
  font-family: "MyFont";
  font-size: 22px;
  color: white;
  background-color: rgb(5, 143, 223);
  border: 3px solid rgb(5, 143, 223);
  border-radius: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
}

.get-to-know-btn:hover {
  color: rgb(5, 143, 223);
  background-color: white;
}
.right-arrow-icon {
  margin-left: 5px;
}
.devicon-linkedin-plain,
.devicon-github-original {
  font-size: 42px;
  color: rgb(5, 143, 223);
}
.github-btn-link,
.linkedin-btn-link {
  text-decoration: none;
}
.devicon-linkedin-plain:hover,
.devicon-github-original:hover {
  color: white;
  background-color: rgb(5, 143, 223);
  border: none;
}
.devicon-github-original:hover {
  border-radius: 1em;
}
@media (max-width: 1300px) {
  .home-container {
    gap: 25px;
  }
}
@media (max-width: 1050px) {
  .home-img {
    width: 460px;
    height: auto;
  }
  .home-container {
    gap: 0px;
  }
  .home-heading {
    font-size: 52px;
  }
  .home-desc {
    font-size: 48px;
  }
}
@media (max-width: 900px) {
  .home-heading {
    font-size: 50px;
  }
  .home-desc {
    font-size: 46px;
  }
}
@media (max-width: 850px) {
  .home-container {
    display: flex;
    flex-direction: column;
    gap: 0;
    text-align: center;
    justify-content: flex-start;
  }

  .home-img {
    margin: 0;
    padding: 0;
  }
  .home-container-infos {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    order: 1;
  }
  .home-heading {
    font-size: 50px;
  }
  .home-desc {
    font-size: 48px;
  }
  .home-container-links {
    display: flex;
    gap: 30px;
  }
}

@media (max-width: 450px) {
  .home-container {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .home-img {
    width: 350px;
    height: auto;
  }
  .home-heading {
    font-size: 40px;
  }
  .home-desc {
    font-size: 38px;
  }
  .home-container-links {
    display: flex;
    gap: 10px;
  }
  .get-to-know-btn {
    font-size: 18px;
    padding: 5px;
  }
  .devicon-linkedin-plain,
  .devicon-github-original {
    font-size: 30px;
  }
}

/* animation */

.home-heading {
  animation: 1.5s fade-in ease-in;
}

.home-desc {
  animation: 1.5s fade-in ease-in;
}
.get-to-know-btn {
  animation: 2.5s fade-in ease-in;
}
