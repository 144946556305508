.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.about-heading {
  font-family: "MyFont";
  text-align: center;
  border-bottom: 2px solid rgb(5, 143, 223);
}
.about-heading > span {
  color: rgb(5, 143, 223);
}

.about-paragraph {
  font-family: "MyFont";
  font-size: 17px;
  font-weight: 100;
  max-width: 900px;
  padding: 20px 30px 60px 30px;
  border-bottom: 2px solid rgb(5, 143, 223);
}

@media (max-width: 1100px) {
  .about-paragraph {
    max-width: 800px;
  }
  .skills-container {
    max-width: 800px;
  }
}
@media (max-width: 850px) {
  .about-paragraph {
    max-width: 600px;
    padding: 10px 35px 40px 35px;
  }
  .skills-container {
    max-width: 600px;
    padding: 10px 35px 40px 35px;
  }
}

.skills-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  max-width: 1000px;
  gap: 30px;
  padding: 10px 30px 30px 30px;
}

.skills-tools {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  padding: 100px 50px 50px 50px;
}

.skills-tools-heading {
  text-align: center;
  font-family: "MyFont", sans-serif;
  font-size: 28px;
}
.skills-tools-heading > span {
  color: rgb(5, 143, 223);
}

.skill-stack-title {
  font-family: "MyFont", sans-serif;
  padding: 10px;
}
.skills-img-container {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  font-family: "MyFont";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills-img-container > img {
  max-width: 80px;
}
