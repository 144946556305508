.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.projects-heading {
  padding-top: 60px;
  margin-top: 50px;
  font-family: "MyFont", sans-serif;
  border-bottom: 2px solid rgb(5, 143, 223);
}
.projects-heading > span {
  color: rgb(5, 143, 223);
}
.projects-container {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  padding-bottom: 30px;
  margin-top: 50px;
  gap: 100px;
}

.projects-card {
  padding: 40px;
  display: flex;
  align-items: center;
  gap: 30px;
  border-top: 1px solid rgba(5, 143, 223, 0.8);
  border-bottom: 1px solid rgba(5, 143, 223, 0.8);
}

.projects-card > img {
  width: 100%;
  max-width: 350px;
}
.card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "MyFont", sans-serif;
}

.project-links {
  font-size: 20px;
  text-decoration: none;
  color: black;
  border: 2px solid rgb(5, 143, 223);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.project-links:hover {
  color: rgb(5, 143, 223);
  font-weight: bold;
}
.card-info > div {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5%;
  text-align: center;
  align-items: center;
}
.card-title > span {
  color: rgb(5, 143, 223);
}
.card-techstack {
  color: rgb(5, 143, 223);
  font-weight: bold;
}

/* Media Queries */

@media (max-width: 1100px) {
  .projects-container {
    max-width: 800px;
  }
}
@media (max-width: 850px) {
  .projects-container {
    max-width: 800px;

    padding: 10px 35px 40px 35px;
  }
  .projects-card {
    flex-direction: column;
  }
  .card-info > p {
    text-align: center;
  }
  .odd {
    order: 1;
  }
}
